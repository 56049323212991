import React, { useState, useEffect } from 'react';
import { Container } from '../../../../components/Container';
import { Icon } from '../../../../components/Icons';
import { Location } from '../../../../components/Location';
import { useListener, usePrevious } from '../../../../utils/hooks';
import { MenuDesktop } from '../MenuDesktop';
import { Items } from '../MenuDesktop/ItemsMenuDesktop';
import { MyAccount } from '../MyAccount';
import { ContainerHeader } from './ContainerHeader';
import styles from './HeaderMenu.module.css';

interface Props {
  itemsMenu: Items[];
  onClick?: () => void;
  menuHasTwoColors?: boolean;
  selectedItem?: string;
}
export const HeaderMenu = ({
  itemsMenu,
  onClick,
  menuHasTwoColors = true,
  selectedItem,
}: Props) => {
  const [isMenuOnTop, setIsMenuOnTop] = useState(true);
  const [scrollPos, setScrollPos] = useState<number>(0);
  const [visible, setVisible] = useState(true);
  const prevScrollpos = usePrevious(scrollPos);
  if (typeof window !== 'undefined') {
    const handleScroll = () => {
      setScrollPos(window.scrollY || document.documentElement.scrollTop);
    };
    useListener(window, 'scroll', handleScroll);
  }
  useEffect(() => {
    if (scrollPos <= 72) {
      setIsMenuOnTop(true);
    } else {
      setIsMenuOnTop(false);
    }
    if ((prevScrollpos as unknown as number) > scrollPos) {
      setVisible(true);
    }
    // if (scrollPos > 72 && (prevScrollpos as unknown as number) <= scrollPos) {
    //   setVisible(false);
    // }
  }, [scrollPos]);
  const renderLogoOnHeader = () => {
    const icon =
      isMenuOnTop && menuHasTwoColors
        ? 'SvgIconLogoAutoscarInlineWhite'
        : 'SvgIconLogoAutoscarInline';
    return <Icon name={icon} className={styles.resizeIcon} />;
  };
  const locationClass = isMenuOnTop && menuHasTwoColors ? 'white' : 'abbey';
  return (
    <ContainerHeader isVisible={visible} isMenuOnTop={isMenuOnTop && menuHasTwoColors}>
      <Container>
        <div className="pv16 flex w-100">
          <div className="w-100 w-50-m w-50-l flex justify-between justify-start-m justify-start-l items-center white">
            <a href="/" title="Ir para a página inicial" aria-label="Ir para a página inicial">
              {renderLogoOnHeader()}
            </a>
            <div className="ml16 pl12-m pl12-l bl-m bl-l b--white">
              <Location className={locationClass} />
            </div>
          </div>
          <div className="dn flex-m flex-l w-70 items-center justify-end">
            <MenuDesktop
              isMenuOnTop={isMenuOnTop && menuHasTwoColors}
              itemsMenu={itemsMenu}
              selectedItem={selectedItem}
            />
            <MyAccount onClick={onClick} />
          </div>
        </div>
      </Container>
    </ContainerHeader>
  );
};
